import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXLayout.tsx";
import SEO from 'components/SEO';
import CauseHero from 'components/CauseHero';
import { graphql } from 'gatsby';
import { Box } from 'components/Box';
import { Text } from 'components/Text';
import BackgroundImage from 'gatsby-background-image';
import { Container } from 'components/Container';
import { ContainerImageWide } from 'components/ContainerImageWide';
import { CallToAction } from 'components/CallToAction';
export const pageQuery = graphql`
  query Ansar {
    heroImage: file(relativePath: {eq: "project-ansar-hero.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    footerImage: file(relativePath: {eq: "project-ansar-footer.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Become an UMMATY Ambassador" description="Through its ZAKAT program, UMMATY offers you the possibility to fulfill this obligation in a single click. We ensure that 100% of your Ansar donation amounts (no administration fees applied) are spent on the beneficiaries categories mentioned in the Holy Qur'an." mdxType="SEO" />
    <ContainerImageWide fluid={props.data.heroImage.childImageSharp.fluid} mdxType="ContainerImageWide">
  <CallToAction purpose="ansar" title="Ansar Ummaty" text="One single donation, Multiple causes supported" buttonText="Donate Now" pagePath={props.path} mdxType="CallToAction" />
    </ContainerImageWide>
    <Container mdxType="Container">
  <Text mb={0} mdxType="Text">
    <div>
      Be part of our exceptional supporters: a <strong>single fund</strong> that
      will help <strong>serve multiple causes</strong> and get the
      <strong> rewards</strong> for: <strong>Safe Drinking Water</strong> (Water
      wells),
      <strong> Orphans Care, Education, Food Aid</strong> programs,
      <strong> Refugees support</strong>, and much more.
    </div>
    <br />
    <div>
      We all dream of being able to help the needy, to save lives and to
      alleviate suffering of our brothers and sisters around the world. But the
      needs are many and we often don't know where to start. UMMATY offers you
      the opportunity to be able, through a single action, a single donation, to
      help carry out various humanitarian projects, in different parts of the
      world. The ANSAR FUND fund was created to support and help develop the
      actions of UMMATY. These actions include the supply of drinking water, the
      provision of food aids for refugees and for the most vulnerable
      populations, orphans care and education.
    </div>
    <br />
    <h3>
      1 Donation = Rewards for Water Projects + Orphan Care + Education +
      Refugees support + Food Aid{' '}
    </h3>
    <h2>..and ALLAH multiplies the rewards for whomever He wills.</h2>
  </Text>
  <></>
    </Container>
    <div style={{
      "backgroundColor": "#F4F7F8",
      "width": "100%"
    }}>
  <Container mdxType="Container">
    <Text mb={0} mdxType="Text">
      <div>
        Although UMMATY is a <strong>100% volunteer based organization</strong>,
        with not a single dollars spent on salaries, there are important costs
        involved to deliver our projects. These include logistics,
        communication, fundraising, etc. Without spending on these aspects, the
        different UMMATY projects that are benefiting to dozens of thousands of
        people around the world could never be completed. That is why the
        <strong>
          ANSAR Fund was created, to cover all these types of operating costs.
        </strong>
        This also enables us to propose and execute projects with
        <strong> 0% administration fees deducted from your donations.</strong>
      </div>
      <br />
      <div>
        By donating One single donation to the Ansar fund, you join the
        Supporters of UMMATY, and inshaAllah, get the rewards for all our
        current and future projects, as this fund will be used to support our
        capacity to develop them all.
      </div>
    </Text>
    <></>
  </Container>
    </div>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      